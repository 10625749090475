import "./content.scss";
import games from "./img/games.svg";
import Product from "./Product";
import product1 from "./img/products/img.png";
import product2 from "./img/products/img_1.png";
import product3 from "./img/products/img_2.png";
import product4 from "./img/products/img_3.png";
import product5 from "./img/products/img_4.png";
import product6 from "./img/products/img_5.png";
import product7 from "./img/products/img_6.png";
import product8 from "./img/products/img_7.png";
import product9 from "./img/products/img_8.png";
import product10 from "./img/products/img_9.png";
import product11 from "./img/products/img_10.png";
import product12 from "./img/products/img_11.png";
import product13 from "./img/products/img_12.png";
import product14 from "./img/products/img_13.png";
import product15 from "./img/products/img_14.png";
import product16 from "./img/products/img_15.png";
import banner from "./img/banner.png";
export default function ContentPage() {
    return (
        <div className={"content"}>
            <h3>New & Sale</h3>
            <img src={banner} alt={"banner"}/>
            <div><h3>Categories</h3>
                <div className={"categories"}>
                    <div className={"category"}>Keycaps</div>
                    <div className={"category"}>Games</div>
                    <div className={"category"}>Art</div>
                    <div className={"category"}>Merch</div>
                    <div className={"category"}>Accessories</div>
                    <div className={"category"}>Posters</div>
                    <div className={"category"}>Figures</div>
                    <div className={"category"}>Mousepads</div>


                </div>
            </div>
            <div className={"games-filter"}><h3>Filter by game</h3>
                <img src={games} alt={"games"}/>
            </div>
            <div><h3>All products</h3>
                <div className={"products"} style={{marginTop:'12px'}}>
                    <Product name={"Handmade Starwars stormvictor  keycap for MX keyboards"} price={"45"} img={product1} info={
                        {
                            likes: 84,
                            delivery: "~12 days",
                            comments: 20
                        }
                    }/>
                    <Product name={"Pudge figure handmade 3d printed game "} price={"320"} img={product2} info={
                        {
                            likes: 120,
                            delivery: "~12 days",
                            comments: 10}}/>
                    <Product name={"Poster with Grimstroke from Dota2. Wall decoration"} price={"32"} img={product3} info={
                        {
                            likes: 56,
                            delivery: "~12 days",
                            comments: 20}}/>
                    <Product name={"Keycap for mx keyboards dota2 logo Steel"} price={"46"} img={product4} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                </div>
                <div className={"products"} style={{marginTop:'12px'}}>
                    <Product name={"Handmade Starwars 3d printed stormtroopers figures"} price={"10"} img={product5} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20
                        }
                    }/>
                    <Product name={"Handmade Star wars clocks, Wall decoration"} price={"20"} img={product6} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                    <Product name={"Dart wader controller holder with custom sign"} price={"100"} img={product7} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                    <Product name={"Star wars aqua posters bundle. Wall decor"} price={"100"} img={product8} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                </div>
                <div className={"products"} style={{marginTop:'12px'}}>
                    <Product name={"Starwars socks  "} price={"100"} img={product9} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20
                        }
                    }/>
                    <Product name={"Handmade r2d2 keycap for mx keyboards"} price={"100"} img={product10} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                    <Product name={"Spider man 3d printed figure handmade "} price={"100"} img={product11} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                    <Product name={"Drow ranger figures dota2 hero. Handmade"} price={"100"} img={product12} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                </div>
                <div className={"products"} style={{marginTop:'12px'}}>
                    <Product name={"Handmade 3d printed antimag figure dota2 hero"} price={"100"} img={product13} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20
                        }
                    }/>
                    <Product name={"Witch doctor poster dota2 hero wall decor"} price={"100"} img={product14} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                    <Product name={"Hoodwink poster dota2 hero. Wall decor"} price={"100"} img={product15} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                    <Product name={"Faceless void dino figure dota2 hero. 3d printed"} price={"100"} img={product16} info={
                        {
                            likes: 100,
                            delivery: "~2 days",
                            comments: 20}}/>
                </div>

            </div>

        </div>
    );
}

